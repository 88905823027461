import { Injectable } from '@angular/core';
import { Task } from '../../models/task.model';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


interface QueryConfig {
  path: string; //  path to collection
  field: string; // field to orderBy
  limit: number; // limit per query
  reverse: boolean; // reverse order?
  prepend: boolean; // prepend to source?
}

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  task: any;

  private query: QueryConfig = {
    field: 'task_deadline_until',
    limit: 50,
    path: 'tasks',
    prepend: true,
    reverse: true
  };

  
  private tasksCollection: AngularFirestoreCollection<Task>;

  private tasks: Observable<Task[]>;

  constructor(public db: AngularFirestore) {
    /* this.tasksCollection = db.collection<Task>('tasks');

    this.tasks = this.tasksCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    ); */
    this.tasksCollection = this.db.collection<Task>('tasks');

    this.tasks = this.tasksCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
        });
      })
    );
  }


  getTasks() {
    this.tasksCollection = this.db.collection<Task>('tasks');

    this.tasks = this.tasksCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
        });
      })
    );

    const first = this.db.collection(this.query.path, ref => {
      return ref.orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
                .limit(this.query.limit);
      });
      return first.snapshotChanges().pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data as {}};
          });
        })
    );
  }

  getAllTask() {
    return this.tasksCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  getActiveTasks(date) {
    const applicant = this.db.collection(this.query.path, ref => {
      return ref.where('start_date', '<=', date) && ref.where('task_deadline_until_string', '>=', date)
    });
    return applicant.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  addTask(task: Task, postId: any) {
    return this.tasksCollection.doc(postId).set(task);
  }

  getTask(id= '19') {
    return this.tasksCollection.doc<Task>(id).valueChanges();
  }
  
  deleteTask(id= '19') {
    return this.tasksCollection.doc(id).delete();
  }

  updateTask(id, task: Task) {
    return this.tasksCollection.doc(id).update(task);
  }

  updateTaskbDatat(id, task: any) {
    return this.tasksCollection.doc(id).update(task);
  }


  getWithLimit( starting: any)  {
    const first = this.db.collection(this.query.path, ref => {
      return ref.orderBy(this.query.field)
              .startAfter(starting)
              .limit(this.query.limit);
    });

    return first.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data as {}};
        });
      })
    );
  }
}
