import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ApplicantsService {
  private applicantsCollection: AngularFirestoreCollection<any>;

  private applicants: Observable<any>;

  constructor(public db: AngularFirestore) {
    this.applicantsCollection = db.collection<any>('applicants');

    this.applicants = this.applicantsCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, data };
        });
      })
    );
  }

  addApplicant(id, applicants) {
    return this.applicantsCollection.doc(id).set(applicants);
  }
  getApplicants(id) {
    return this.applicantsCollection.doc<any>(id).valueChanges();
  }
  getApplicantsData() {
    return this.applicants = this.applicantsCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, data };
        });
      })
    );
  }
  updateApplicants(id, applicants) {
    return this.applicantsCollection.doc(id).update(applicants);
  }

}
