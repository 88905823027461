import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/auth';
import { FIREBASE_CONFIG } from './firebase.credentials';
import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';

import { AppComponent } from './app.component';
import { HomePage } from './pages/home/home.page';
import { LoginPage } from './pages/login/login.page';
import { EmailComposer } from '@ionic-native/email-composer/ngx';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { NetworkPageModule } from './pages/network/network.module';

import { File } from '@ionic-native/file/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';

import { IonicSelectableModule } from 'ionic-selectable';

import { ThemeableBrowser } from '@ionic-native/themeable-browser/ngx';
import { ImagePicker } from '@ionic-native/image-picker/ngx';

import { InAppPurchase } from '@ionic-native/in-app-purchase/ngx';
import { AngularFireStorageModule } from '@angular/fire/storage';
import {  HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { IonicStorageModule } from '@ionic/storage';
import { LanguagePopoverPageModule } from './pages/language-popover/language-popover.module';
import { MomentModule } from 'ngx-moment';
import { PreviousPositionsModalPageModule } from './pages/_pilot/previous-positions/previous-positions-modal/previous-positions-modal.module';

import { FeedsettingComponent } from './pages/feed/feedsetting/feedsetting.component';
import { AddProfessionPage } from './pages/_private/add-profession/add-profession.page';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}
@NgModule({
  declarations: [AppComponent, FeedsettingComponent, AddProfessionPage],
  entryComponents: [
    FeedsettingComponent, AddProfessionPage
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AngularFireModule.initializeApp(FIREBASE_CONFIG),
    AppRoutingModule,
    HttpClientModule, FormsModule, ReactiveFormsModule,
    NetworkPageModule,
    PreviousPositionsModalPageModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    IonicSelectableModule,
    AngularFireStorageModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    LanguagePopoverPageModule,
    TranslateModule,
    MomentModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    AngularFireAuth,
    File,
    FileChooser,
    FilePath,
    EmailComposer,
    ThemeableBrowser,
    ImagePicker,
    InAppPurchase,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: FirestoreSettingsToken, useValue: {} }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
