import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { AngularFireAuth } from "@angular/fire/auth";
import { LoginService } from "../../../services/login/login.service";
import { Storage } from "@ionic/storage";
import { TaskService } from '../../../services/task/task.service';
import applicants from "../../../../assets/i18n/en_applicants.json";
import daapplicants from "../../../../assets/i18n/da_applicants.json";
import { Task } from "../../../models/task.model";
const LNG_KEY = "SELECTED_LANGUAGE";

@Component({
  selector: "app-add-profession",
  templateUrl: "./add-profession.page.html",
  styleUrls: ["./add-profession.page.scss"],
})
export class AddProfessionPage implements OnInit {
  professions: any;
  uid: any;
  professionData = [];
  userRole: any;
  selected = "";
  myProfessionData = [];

  speciality: any;
  displaySpecialities = [];
  applicantDetails = {};
  found: boolean = false;
  updatedValue: any;
  onInitFlag: any = 0;
  slug: any;
  defaultCheck: boolean = false;
  titleName: any;

  modalTitle: string;
  postId: any;
  finalprofessionData: any[];

  job: Task = {
    id: '',
    userid: JSON.parse(localStorage.getItem('user')).uid,
    
    headline: '',
    title: '',
    text: '',
    streetname: '',
    city: '',
    country: '',

    private_task_type: '',

    private_id: JSON.parse(localStorage.getItem('user')).uid,
    private_logo: JSON.parse(localStorage.getItem('user')).photoURL,
    private_name: JSON.parse(localStorage.getItem('user')).displayName,

    task_deadline_until: new Date(new Date().setDate(new Date().getDate()+30)).toISOString(),
    task_deadline_until_string: new Date(new Date().setDate(new Date().getDate()+30)).toISOString(),
    
    created_date: new Date().toISOString(),
    images: []
  };
  isTaskAvalable: Boolean = false;

  constructor(
    private modalController: ModalController,
    private taskService: TaskService,
    private todoService: LoginService,
    private storage: Storage,
    private angularAuthService: AngularFireAuth,
    private navParams: NavParams
  ) {
    this.uid = JSON.parse(sessionStorage.getItem("user")).uid;

    this.storage.get(LNG_KEY).then((lng) => {
      if (lng == "en") {
        this.selected = "en";
      } else {
        this.selected = "da";
      }
    });

    if (this.uid == undefined && this.uid == null) {
      this.angularAuthService.auth.onAuthStateChanged(async (user) => {
        if (user) {
          this.uid = user.uid;
        }
      });
    }
  }

  ngOnInit() {
    this.modalTitle = this.navParams.data.paramTitle;
    this.postId = this.navParams.data.postId;
    
    this.slug = this.modalTitle;

    this.storage.get(LNG_KEY).then((lng) => {
      if (lng == "en") {
        this.selected = "en";
        if (
          this.modalTitle == null ||
          this.modalTitle == "" ||
          this.modalTitle == undefined
        ) {
          this.getAllUserProfession();
        } else {
          this.getDetailsProfession();
        }
      } else {
        this.selected = "da";
        if (
          this.modalTitle == null ||
          this.modalTitle == "" ||
          this.modalTitle == undefined
        ) {
          this.getAllUserProfession();
        } else {
          this.getDetailsProfession();
        }
      }
    });
  }

  getAllUserProfession() {
    this.todoService.getUser(this.uid).subscribe((res) => {
      this.userRole = res.role;
      if (this.userRole) {
        this.finalprofessionData = [];
          this.taskService.getTask(this.postId).subscribe((res) => {           
            if(res){
              this.isTaskAvalable = true;
              this.finalprofessionData.push(res.private_task_type);
              this.professions = res.private_task_type;
            } else {
              this.isTaskAvalable = false;
            }

            //this.professions = res;
            this.professionData = [];
            this.myProfessionData = [];
        
            if (this.selected == "da") {
              if(res != undefined || res != null || res)
              {
                for (var key in applicants) {
                  const foundSpecialities = this.professionData.find((element) => element.name == key);
                  if (foundSpecialities == undefined) {
                    this.myProfessionData.push({
                      profession: daapplicants[key], 
                      value: res != undefined && res[key] != undefined ? true : false,
                    });
                    this.professionData.push({
                      profession: key,
                      value: res.private_task_type != undefined && res.private_task_type[key] != undefined ? true : false,
                    });
                  }
                }
              } else {
                for (var key in applicants) {
                  const foundSpecialities = this.professionData.find((element) => element.name == key);
                  if (foundSpecialities == undefined) {
                    this.myProfessionData.push({
                      profession: daapplicants[key], 
                      value: res != undefined && res[key] != undefined ? true : false,
                    });
                    this.professionData.push({
                      profession: key,
                      value: res != undefined && res[key] != undefined ? true : false,
                    });
                  }
                }
              }
            } else {
              if(res != undefined || res != null || res)
              {
                for (var key in applicants) {
                  const foundSpecialities = this.professionData.find((element) => element.name == key);
                  if (foundSpecialities == undefined) {
                    this.professionData.push({
                      profession: key,
                      value: res.private_task_type != undefined && res.private_task_type[key] != undefined ? true : false,
                    });
                  }
                }
              } else {
                for (var key in applicants) {
                  const foundSpecialities = this.professionData.find((element) => element.name == key);
                  if (foundSpecialities == undefined) {
                    this.professionData.push({
                      profession: key,
                      value: res != undefined && res[key] != undefined ? true : false,
                    });
                  }
                }
              }
            }
          });
      }
    });
  }

  saveProfession(profession) {
    if (this.professions == undefined) {
      this.professions = {};
    }
    if (this.userRole) {
      const foundSpecialitiesIndex = this.professionData.findIndex((element) => element.profession == profession);
      if (this.professionData[foundSpecialitiesIndex].value == false) {
        this.professions[profession] = {};
        for (var key in applicants[profession]) {
          this.professions[profession][key] = applicants[profession][key] == "textbox" ? "" : false;
        }
        this.job.private_task_type = this.professions;
        //this.privateprofessionService.addApplicant(this.uid, this.professions);
        this.finalprofessionData.push(this.professions);
        if(this.isTaskAvalable){
          this.taskService.updateTaskbDatat(this.postId, { "private_task_type": this.professions});
        } else {
          this.taskService.addTask(this.job, this.postId);
        }
        
      } else {
        if (this.professions != undefined) {
          if (profession in this.professions) {
            delete this.professions[profession];
            //this.privateprofessionService.addApplicant(this.uid, this.professions);
            if(this.isTaskAvalable){
              //this.taskService.updateTaskb(this.postId, this.job);
              this.finalprofessionData.push(this.professions);
              this.taskService.updateTaskbDatat(this.postId, { "private_task_type": this.professions});
            } else {
              this.taskService.addTask(this.job, this.postId);
            }
          }
        }
      }
      localStorage.setItem("currentTaskid",this.postId);
    }
  }

   getDetailsProfession() {
    this.uid = JSON.parse(sessionStorage.getItem("user")).uid;
    this.todoService.getUser(this.uid).subscribe((mUser) => {
      this.userRole = mUser.role;
      if (this.userRole) {
        this.speciality = applicants[this.slug];

        //this.privateprofessionService.getApplicants(this.uid).subscribe((res) => {
        this.taskService.getTask(this.postId).subscribe((profession) => {

            const res = profession.private_task_type;
            this.applicantDetails = res;
            if (res != undefined) {
              this.found = true;
            }
            for (var key in this.speciality) {
              const foundSpecialities = this.displaySpecialities.find(
                (element) => element.name == key
              );
              if (foundSpecialities == undefined) {
                if (res && res[this.slug]) {
                  this.displaySpecialities.push({
                    name: key,
                    value:
                      this.applicantDetails[this.slug] != undefined &&
                      this.applicantDetails[this.slug][key] != undefined
                        ? this.applicantDetails[this.slug][key]
                        : this.speciality[key] == "textbox"
                        ? ""
                        : false,
                    type: this.speciality[key],
                  });
                } else {
                  this.displaySpecialities.push({
                    name: key,
                    value: this.speciality[key] == "textbox" ? "" : false,
                    type: this.speciality[key],
                  });
                  if (this.applicantDetails == undefined) {
                    this.applicantDetails = {};
                  }
                  if (this.applicantDetails[this.slug] == undefined) {
                    this.applicantDetails[this.slug] = {};
                  }
                  this.applicantDetails[this.slug][key] =
                    this.speciality[key] == "textbox" ? "" : false;
                }
              }
            }
          });
      }
    });
  }

  saveSpeciality(item, val) {
    if (this.userRole) {
      this.applicantDetails[this.slug][item] = val;
      if (!this.found) {
        //this.privateprofessionService.addApplicant(this.uid,this.applicantDetails);
        this.taskService.addTask(this.postId,{ "private_task_type": this.applicantDetails});
      } else {
        //this.privateprofessionService.updateApplicants(this.uid,this.applicantDetails);
        this.taskService.updateTaskbDatat(this.postId, { "private_task_type": this.applicantDetails});
      }
    }
  }

  stringSplit(selLng) {
    if (this.userRole) {
      if (this.selected == "da") {
        return daapplicants[selLng];
      } else {
        return selLng;
      }
    }
  }

  async closeModal() {
    await this.modalController.dismiss();
  }
}
