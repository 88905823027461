import { Component, OnInit, ViewChild } from '@angular/core';
import { ApplicantService } from '../../services/pilot/pilot.service';
import { JobService } from '../../services/job/job.service';
import { AlertController, LoadingController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../services/login/login.service';
import { SubscriptionsService } from '../../services/subscriptions/subscriptions.service';
import * as firebase from 'firebase';
import { NavController } from '@ionic/angular';
import { IonInfiniteScroll } from '@ionic/angular';
import { PopoverController } from '@ionic/angular';
import { TaskService } from './../../services/task/task.service';
import { LanguagePopoverPage } from '../../pages/language-popover/language-popover.page';
import { TranslateService } from '@ngx-translate/core';
import { ApplicantsService } from '../../services/applicants/applicants.service';
import applicants from '../../../assets/i18n/en_applicants.json';
import daapplicants from '../../../assets/i18n/da_applicants.json';
import dacompanies from '../../../assets/i18n/da_companies.json';

import moment from 'moment';
import { AngularFireAuth } from '@angular/fire/auth';
import { Storage } from '@ionic/storage';
const LNG_KEY = 'SELECTED_LANGUAGE';

@Component({
  selector: 'app-network',
  templateUrl: './network.page.html',
  styleUrls: ['./network.page.scss'],
})
export class NetworkPage implements OnInit {

  subscriptionUser: any = [];
  subscriberPlanUser: Boolean = false;
  loggedInOrNot = false;
  applicantUsers: any = [];
  verified: any = [];
  userAuthCheck: any = [];
  professionData: any[]; Router
  applicantData: any = [];
  professions: any = [];
  applicant: any = [];
  position: string;

  searchProfessions = [];
  selected = '';

  constructor(
    private applicantService: ApplicantService,
    private router: Router,
    private route: ActivatedRoute,
    private jobService: JobService,
    public alertController: AlertController,
    private loginService: LoginService,
    private subscriptionsService: SubscriptionsService,
    public loadingController: LoadingController,
    private navCtrl: NavController,
    private taskService: TaskService,
    private angularAuthService: AngularFireAuth,
    private popoverCtrl: PopoverController,
    private alertCtrl: AlertController,
    private translate: TranslateService,
    private storage: Storage,
    public professionsService: ApplicantsService,
  ) {
    //this.stringFilterSplit();
    for (var key in applicants) {
      this.searchProfessions.push(key);
    }

    this.storage.get(LNG_KEY).then(lng => {
      if (lng == 'en') {
          this.selected = 'en';
      } else {
          this.selected = 'da';
      }
    });

    this.ownProfile = JSON.parse(sessionStorage.getItem('user'));
    this.count = 0;

    if (JSON.parse(sessionStorage.getItem('user')) != null) {
      this.loggedInOrNot = true;
    } else {
      this.loggedInOrNot = false;
    }

    if (JSON.parse(sessionStorage.getItem('user')) != null) {
      this.subscriptionsService.getUserSubscription(JSON.parse(sessionStorage.getItem('user')).uid).subscribe(subscrip => {
        if (subscrip != null) {
          this.subscriptionUser = subscrip;
          if (JSON.parse(sessionStorage.getItem('user')).uid == this.subscriptionUser[JSON.parse(sessionStorage.getItem('user')).uid].id) {
            this.subscriberPlanUser = true;
          } else {
            this.subscriberPlanUser = false;
          }
        }
      });
    }

    this.subscriptionsService.getSubscription().subscribe(subscrip => {
      this.subscripUser = [];
      if (subscrip != null) {
        this.allSubscripUser = subscrip;
        // this.subscripUser = [];
        for (let ind = 0; ind < subscrip.length; ind++) {
          this.subscripUser.push(subscrip[ind].id);
          // console.log('===>' + this.subscripUser[ind]);
        }
      }
    });
  }

  //@ViewChild(IonInfiniteScroll, {static: false}) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonInfiniteScroll, { static: false } as any) infiniteScroll: IonInfiniteScroll;

  user: any = JSON.parse(sessionStorage.getItem('user'));

  pilots: any = [];
  photo = 'assets/imgs/blank_pp.png';
  view = 'pilot';

  ownProfile: any;
  starting: any;
  userStarting: any;
  applicantSearch: any = [];

  serach = '';

  page = 0;
  count: any = 0;
  countCompanies: any = 0;
  filter: any = 0;

  companies: any = [];
  anotherCompanies: any = [];
  companySearch: any;


  /* countPrivateTasks: any = 0;

  privateTask: any = [];
  privateTaskSearch: any = []; */

  searchWithLicense: any;
  searchWithFixedWingHours: any;
  searchWithHelicopterHours: any;
  itemHelicopterHoursDisabled: any;
  itemFixedWingDisabled: any;
  searchStudentPilot: any;

  searchWithProfessions: any;

  license = [
    { name: 'ATPL(A)', value: 'atplA' },
    { name: 'CPL(A)', value: 'cplA' },
    { name: 'ATPL(H)', value: 'atplH' },
    { name: 'CPL(H)', value: 'cplH' },
  ];

  studentPilot = [
    { name: 'Student Pilot', value: 'true' }
  ];

  hours = [
    { name: '0', value: '0' },
    { name: '500', value: '500' },
    { name: '1000', value: '1000' },
    { name: '3000', value: '3000' },
  ];

  customAlertOptions: any = {
    header: 'License List',
    subHeader: 'Select license type',
    translucent: true
  };

  customAlertHourOptions: any = {
    header: 'Hours',
    subHeader: 'Select Hours For Fixed Wing',
    translucent: true
  };


  customAlertProfessionOptions: any = {
    header: 'Profession',
    subHeader: 'Select Profession',
    translucent: true
  };


  allSubscripUser: any = [];
  subscripUser: any = [];
  checkUserList: any = [];

  async ngOnInit() {
    this.storage.get(LNG_KEY).then(lng => {
      if (lng == 'en') {
          this.selected = 'en';
      } else {
          this.selected = 'da';
      }
    });

    this.loginService.getAllUser().subscribe(data => {
      this.countCompanies = data.length;
    });
    this.route.params.forEach(params => {
      this.loadApplicantData();
    });
    /* this.privateTaskView(); */
  }

  getSubscriptionData(userid) {
    if (this.subscripUser != null) {
      const data = this.subscripUser.indexOf(userid);
      if (data > -1) {
        return true;
      } else {
        return false;
      }
    }
  }

  /* onGotoPrivateDetails(c) {
    this.navCtrl.navigateForward(`/tabs/profile/${c.id})`);
  } */
  onGotoApplicantDetails(c) {
    this.navCtrl.navigateForward(`/tabs/profile/${c.id})`);
  }
  onGotoCOmpayDetails(c) {
    this.navCtrl.navigateForward([`/company-details/${c.id}`]);
  }
  async presentAlert() {
    const alert = await this.alertController.create({
      header: this.translate.instant('ALERT.warning'),
      message: this.translate.instant('ALERT.NETWORK.sorry_you_did_not'),
      buttons: ['OK']
    });
    await alert.present();
  }

  companyView() {
    this.view = 'company';
    this.loginService.getUsers().subscribe(companies => {
      this.companies = companies;
      this.companySearch = [];

      if (Object.entries(this.companies).length !== 0) {
        this.userStarting = this.companies[this.companies.length - 1].Name;
      }
      this.companySearch = Array.from(new Set(this.companies));
    });
  }

  /* privateTaskView(){
    this.view = 'privatetask';
    this.loginService.getPrivateUsers().subscribe(privateTask => {
      this.countPrivateTasks = privateTask.length;
      this.privateTask = privateTask;
      this.privateTaskSearch = [];

      if (Object.entries(this.privateTask).length !== 0) {
        this.userStarting = this.privateTask[this.privateTask.length - 1].Name;
      }
      this.privateTaskSearch = Array.from(new Set(this.privateTask));
    });
  } */

  applicantView() {
    this.view = 'pilot';
  }

  onChangeTime() {
    const user = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')).uid : null;
    if (user == null) {
      this.router.navigate(['/login']);
    }
  }

  filterBySearch() {
    const user = sessionStorage.getItem('user');
    if (user == null) {
      this.serach = '';
      this.router.navigate(['/login']);
    }
    const searchkey = this.serach;
    this.applicantData = this.pilots.filter(function (el) {
      if (el.name) {
        return el.name.toLowerCase().indexOf(searchkey.toLowerCase()) > -1;
      }
    });
    // TODO: notice casing on Name
    this.companySearch = this.companies.filter(function (er) {
      if (er.Name) {
        return er.Name.toLowerCase().indexOf(searchkey.toLowerCase()) > -1;
      }
    });

     // TODO: notice casing on Name
   /*  this.privateTaskSearch = this.privateTask.filter(function (er) {
      if (er.Name) {
        return er.Name.toLowerCase().indexOf(searchkey.toLowerCase()) > -1;
      }
    }); */
    this.count = 0;
    this.applicantData.map(item => {
      //if ((item.fixed_wing_hour + item.helicopter_hour) > 0) {
      this.count++;
      //}
    });
    this.countCompanies = 0;
    this.companySearch.map(item => {
      this.countCompanies++;
    });

    /* this.countPrivateTasks = 0;
    this.privateTaskSearch.map(item => {
      this.countPrivateTasks++;
    });   */  
  }


  filterSearch() {
    if (this.pilots) {
      this.view = 'pilot';
      this.applicantSearch = [];
      this.count = 0;

      for (let index = 0; index < this.pilots.length; index++) {
        const element = this.pilots[index].professions;

        if (element.includes(this.searchWithProfessions)) {
          this.applicantSearch.push({
              id: this.pilots[index].id,
              name: this.pilots[index].name,
              logo: (this.pilots[index] && this.pilots[index].logo) ? this.pilots[index].logo : '',
              professions: this.pilots[index].professions
          });
          this.checkUserList.push(this.pilots[index].id);
          this.count++;
        }
      }
    }
    if (this.applicantSearch.length != 0) {
      this.applicantData = this.applicantSearch;
    }
  }


  filters() {
    // this.subscriptionsService.getAllSubcription(this.subscriptionUser)
    debugger;
    if (!this.loggedInOrNot) {
      this.router.navigate(['/login']);
      return;
    }
    /*if (this.subscriberPlanUser) {*/
      this.view = 'filters';
      /*this.searchWithFixedWingHours = null;
      this.searchWithHelicopterHours = null;
      this.searchWithLicense = null;
      this.searchStudentPilot = null;*/

      this.searchWithProfessions = null;
    /*} else {
      this.presentPlanExpiryAlertPrompt('This feature is reserved for subscribers. Please <a href=\'/subscriptions\'>click here to upgrade</a>.');
    }*/
  }

  stringFilterSplit(prof){
    if (prof != undefined) {
    const allProf = prof.split(",");
    let keyName:any = [];
      for (let index = 0; index < allProf.length; index++) {
        if (this.selected == 'da') {
          let names = allProf[index].trim();//.split(" - ");
          let title = dacompanies[names];
          keyName.push(title);
        } else {
          let names = allProf[index].trim();//.split(" - ");
          keyName.push(names);
        }
      }
      return keyName;
    }
  }
  


  changeLicense(event) {
    this.searchWithLicense = event.value;
  }

  changeStudent(event) {
    this.searchStudentPilot = event.value;
  }

  changeFixedWingHours(event) {
    this.itemHelicopterHoursDisabled = true;
    this.searchWithFixedWingHours = event.value;
  }
  changeHelicopterHours(event) {
    this.itemFixedWingDisabled = true;
    this.searchWithHelicopterHours = event.value;
  }

  changeProfessions(event){
    this.searchWithProfessions = event.value;
  }

  async loadData(event) {
    const loading = await this.loadingController.create({
      message: this.translate.instant('LOADING.load_more_data')
    });
    // tslint:disable-next-line:no-unused-expression
    this.starting && loading.present();
    event.target.complete();
    // tslint:disable-next-line:no-unused-expression
    if (Object.entries(this.applicantSearch).length == 0) {
      // tslint:disable-next-line: no-unused-expression
      this.starting && this.loginService.getApplicantWithLimit(this.starting).subscribe(data => {
        loading.dismiss();
        //this.applicant = [];
        this.applicant = data;

        /*this.loadApplicantData();
        this.applicantData = this.pilots;
        this.starting = this.applicant[this.applicant.length - 1] ? this.applicant[this.applicant.length - 1].Name : null;*/

        this.professionsService.getApplicantsData().subscribe(res => {
          this.professions = res;
          this.applicantService.getAllApplicant().subscribe(applicant_res => {
            //this.applicantData = [];
            //this.pilots = [];
            //this.count = 0;
            if (this.professions != undefined && this.applicant != undefined) {
              for (var i = 0; i < this.applicant.length; i++) {
                const foundProfessions = this.professions.find(element => element.id == this.applicant[i].id);
                const foundPilot = this.pilots.find(element => element.id == this.applicant[i].id);
                const foundPilotData = applicant_res.find(element => element.id == this.applicant[i].id);
                if (foundPilot == undefined) {
                  if (foundProfessions && Object.keys(foundProfessions.data).length != 0) {
                    let professions = Object.keys(foundProfessions.data).map(function (key) {
                      return key
                    }).join(', ');
                    this.count++;
                    this.pilots.push({
                      id: this.applicant[i].id,
                      name: this.applicant[i].Name,
                      logo: (foundPilotData && foundPilotData.logo) ? foundPilotData.logo : '',
                      professions: professions
                    });
                  } else {
                    this.count++;
                    this.pilots.push({
                      id: this.applicant[i].id,
                      name: this.applicant[i].Name,
                      logo: (foundPilotData && foundPilotData.logo) ? foundPilotData.logo : '',
                      professions: ""
                    });
                  }
                }
              }

              this.applicantData = [...this.applicantData, ...this.pilots];
              this.applicantData = Array.from(new Set(this.applicantData));
              if (Object.entries(this.applicantData).length !== 0) {
                this.starting = this.applicant[this.applicant.length - 1] ? this.applicant[this.applicant.length - 1].Name : null;
              }

            }
            loading.dismiss();
          });
        });

      });
    } else {
      loading.dismiss();
    }
  }

  async loadApplicantData() {
    const loading = await this.loadingController.create({
      message: this.translate.instant('LOADING.message')
    });
    loading.present();

    this.loginService.getAllApplicants().subscribe(data => {
      this.applicantUsers = data;
      this.professionsService.getApplicantsData().subscribe(res => {
        this.professions = res;
        this.applicantService.getAllApplicant().subscribe(applicant_res => {
          this.applicantData = [];
          this.pilots = [];
          this.count = 0;
          if (this.professions != undefined && this.applicantUsers != undefined) {
            for (var i = 0; i < this.applicantUsers.length; i++) {
              const foundProfessions = this.professions.find(element => element.id == this.applicantUsers[i].id);
              const foundPilot = this.pilots.find(element => element.id == this.applicantUsers[i].id);
              const foundPilotData = applicant_res.find(element => element.id == this.applicantUsers[i].id);
              if (foundPilot == undefined) {
                if (foundProfessions && Object.keys(foundProfessions.data).length != 0) {
                  let professions = Object.keys(foundProfessions.data).map(function (key) {
                    return key
                  }).join(', ');
                  this.count++;
                  this.pilots.push({
                    id: this.applicantUsers[i].id,
                    name: this.applicantUsers[i].Name,
                    logo: (foundPilotData && foundPilotData.logo) ? foundPilotData.logo : '',
                    professions: professions
                  });
                } else {
                  this.count++;
                  this.pilots.push({
                    id: this.applicantUsers[i].id,
                    name: this.applicantUsers[i].Name,
                    logo: (foundPilotData && foundPilotData.logo) ? foundPilotData.logo : '',
                    professions: ""
                  });
                }
              }
            }




            if (this.pilots.length != 0) {
              this.applicantData = this.pilots

              this.starting = this.pilots[this.pilots.length - 1].name
            }
          }
          loading.dismiss();
        });
      });
    })
  }

  async loadUserData(event) {
    const loading = await this.loadingController.create({
      message: 'Loading More Data'
    });

    // tslint:disable-next-line:no-unused-expression
    this.userStarting && this.countCompanies > this.companySearch.length && loading.present();
    event.target.complete();
    // tslint:disable-next-line:no-unused-expression
    this.userStarting && this.countCompanies > this.companySearch.length &&
      this.loginService.getWithLimit(this.userStarting).subscribe(data => {
        loading.dismiss();
        // todo limit 50 hardcoded.
        // if(this.companySearch.length >= 50){
        // }
        this.companySearch = [...this.companySearch, ...data];
        this.companySearch = this.removeDuplicates(this.companySearch, 'id');
        this.companySearch = Array.from(new Set(this.companySearch));
        if (this.companySearch.length >= this.countCompanies) {
          this.userStarting = null;
        }
      });
  }

  /* async loadPrivateUserData(event) {
    const loading = await this.loadingController.create({
      message: 'Loading More Data'
    });

    // tslint:disable-next-line:no-unused-expression
    this.userStarting && this.countPrivateTasks > this.privateTaskSearch.length && loading.present();
    event.target.complete();
    // tslint:disable-next-line:no-unused-expression
    this.userStarting && this.countPrivateTasks > this.privateTaskSearch.length &&
      this.loginService.getWithLimit(this.userStarting).subscribe(data => {
        loading.dismiss();
        // todo limit 50 hardcoded.
        // if(this.privateTaskSearch.length >= 50){
        // }
        this.privateTaskSearch = [...this.privateTaskSearch, ...data];
        this.privateTaskSearch = this.removeDuplicates(this.privateTaskSearch, 'id');
        this.privateTaskSearch = Array.from(new Set(this.privateTaskSearch));
        if (this.privateTaskSearch.length >= this.countPrivateTasks) {
          this.userStarting = null;
        }
      });
  } */

  removeDuplicates(array, key) {
    let lookup = new Set();
    return array.filter(obj => !lookup.has(obj[key]) && lookup.add(obj[key]));
  }

  async presentPlanExpiryAlertPrompt(msg) {
    const alert = await this.alertController.create({
      header: this.translate.instant('ALERT.JOB_DETAILS.subscription_required'),
      message: msg,
      buttons: [
        {
          text: this.translate.instant('ALERT.cancel_button'),
          role: 'cancel',
          cssClass: 'secondary',
          /*handler: () => {
            }*/
        }, {
          text: 'Ok',
          /*handler: (data) => {
          }*/
        }
      ]
    });
    await alert.present();
  }

  async openLanguagePopover(ev) {
    const popover = await this.popoverCtrl.create({
      component: LanguagePopoverPage,
      event: ev
    });
    await popover.present();
  }

  stringSplit(selLng){
    if (this.selected == 'da') {
      let names = daapplicants[selLng];
      return names;
    } else {
      return selLng;
    }
  }
}
