import { environment } from '../environments/environment';
export const FIREBASE_CONFIG = environment.production ? {
    apiKey: "AIzaSyCAuMidopWa9fgT-TvpUrwIB_FYq2iTtJg",
    authDomain: "hmr-prod-1a0a2.firebaseapp.com",
    databaseURL: "https://hmr-prod-1a0a2.firebaseio.com",
    projectId: "hmr-prod-1a0a2",
    storageBucket: "hmr-prod-1a0a2.appspot.com",
    messagingSenderId: "806717738875",
    appId: "1:806717738875:web:c2d2b1a6edab462d8b77de",
    measurementId: "G-Z5HXLESGBH"
} : {
    apiKey: "AIzaSyDnrUdSoPV42Ds3Is4yw28iP3elpQOtVT4",
    authDomain: "hmr-dev.firebaseapp.com",
    databaseURL: "https://hmr-dev.firebaseio.com",
    projectId: "hmr-dev",
    storageBucket: "hmr-dev.appspot.com",
    messagingSenderId: "916577347274",
    //appId: "1:916577347274:web:53b6fbc30306611a7ff633",
    //measurementId: "G-X297DGG1HW"

    /*apiKey: "AIzaSyAxJC1GR6jPvxv1rAWFkFaAxxdRVpGUaYA",
    authDomain: "apb-dev.firebaseapp.com",
    databaseURL: "https://apb-dev.firebaseio.com",
    projectId: "apb-dev",
    storageBucket: "apb-dev.appspot.com",
    messagingSenderId: "420865992720"*/
};