import { Component } from '@angular/core';
import { Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { LoginService } from './services/login/login.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { ToastController } from '@ionic/angular';
import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { LanguageService } from './services/language.service';
import { PopoverController } from '@ionic/angular';
import { LanguagePopoverPage } from './pages/language-popover/language-popover.page';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';

const LNG_KEY = 'SELECTED_LANGUAGE';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  user: any ;
  linkShow: boolean;

  public appPages = [
    // {
    //   title: 'My Account',
    //   url: '/tabs/(profile:profile)',
    //   icon: 'contact'
    // }
  ];

  //language = '';
  mFoundLanguage: string;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    public alertController: AlertController,
    public loginService: LoginService,
    private angularFiresAuth: AngularFireAuth,
    public toastController: ToastController,
    private lagnuageService: LanguageService,
    private popoverCtrl: PopoverController,
    private alertCtrl: AlertController,
    private translate: TranslateService,
    private storage: Storage
  ) {
    this.initializeApp();
    this.angularFiresAuth.auth.onAuthStateChanged(async (user) => {
      this.user = user;
    });
    this.linkShow = true;
    if (this.platform.is('cordova')) {
        this.linkShow = false;
    }

    this.storage.get(LNG_KEY).then(lng => {
      if (lng == 'en') {
        this.lagnuageService.setLanugage('en');
        this.mFoundLanguage = "English";
      } else {
        this.lagnuageService.setLanugage('da');
        this.mFoundLanguage = "Dansk";
      }
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.lagnuageService.setInitialAppLanguage();
    });
  }
  async openLanguagePopover(ev) {
    const popover = await this.popoverCtrl.create({
      component: LanguagePopoverPage,
      event: ev
    });
    await popover.present();
  }

  changeLanguage(el) {
    this.lagnuageService.setLanugage(el.detail.value);
    console.log("==>"+this.router.url);
    console.log("lan===>"+el.detail.value);
    window.location.reload();
  }




  editprofile() {
    this.router.navigate(['/edit-profile']);
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      header: 'Alert',
      subHeader: 'Subtitle',
      message: 'This is an alert message.',
      buttons: ['OK']
    });

    await alert.present();
  }


  async presentAlertPrompt() {
    const alert = await this.alertController.create({
      header: 'Prompt!',
      inputs: [
        {
          name: 'password',
          placeholder: 'Password',
          type: 'password'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'Ok',
          handler: (data) => {
              const user = this.angularFiresAuth.auth.currentUser;
              user.updatePassword(data.password).then(async function() {
                const toast = await this.toastController.create({
                  message: 'Password Changed Successfully',
                  duration: 2000,
                  position: 'bottom',
                });
                toast.present();
              }).catch(function(error) {
              });
          }
        }
      ]
    });
    await alert.present();
  }


  async presentToast() {
    const toast = await this.toastController.create({
      message: 'Password changed successfully',
      duration: 2000,
      position: 'bottom',
    });
    toast.present();
  }

  logout3() {
    if (this.user) {
      this.angularFiresAuth.auth.signOut().then(async () => {
        window.sessionStorage.clear();
        window.localStorage.clear();
        this.logoutAlert();
      }).catch(err => {

      });
    } else {
    }
  }

  async logoutAlert() {
    window.sessionStorage.clear();
    window.localStorage.clear();
    const alert = await this.alertController.create({
      header: this.translate.instant('ALERT.logout_header'),
      message: this.translate.instant('ALERT.logout_message'),
      buttons: ['OK']
    });
    await alert.present();
    this.router.navigate(['/login']);
  }

  editApplicantCv() {
    this.router.navigate(['/edit-pilot-cv']);
  }
}
