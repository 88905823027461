import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { CountryService } from '../../../../services/country/country.service';

@Component({
  selector: 'app-previous-positions-modal',
  templateUrl: './previous-positions-modal.page.html',
  styleUrls: ['./previous-positions-modal.page.scss'],
})
export class PreviousPositionsModalPage implements OnInit {

  selected: any;
  typeName: any;
  countries: any;

  constructor(private countryService: CountryService,
    public navParams: NavParams,
    public modalCtrl: ModalController) { }

  ngOnInit() {
    this.countries = this.countryService.getCountry();
    this.typeName = this.navParams.get('typeName');
  }

  getSelectCountryItems(ev) {
    this.countries = this.countryService.getCountry();
    var val = ev.target.value;
    if (val && val.trim() != '') {
      this.countries = this.countries.filter((item) => {
        return item.name.toLowerCase().indexOf(val.toLowerCase()) > -1;
      });
    }
  }

  changeSelectCountryItem(item) {
    this.selected = this.countryService.getSelectedFixedWing(item.name)[0];
    this.selected = null;
    this.StartTimer(item.name);
  }

  async StartTimer(cName) {
    setTimeout(() => {
      this.closeModal(cName);
    }, 1000);
  }

  public closeModal(cName) {
    this.modalCtrl.dismiss({
      'dismissed': true,
      "name" : cName
    });
  }
}
