import { Component, OnInit } from '@angular/core';
import { PopoverController, ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-feedsetting',
  templateUrl: './feedsetting.component.html',
  styleUrls: ['./feedsetting.component.scss'],
})
export class FeedsettingComponent implements OnInit {

  constructor(
    private navParams: NavParams,
    private modalController: ModalController,
    private popoverController: PopoverController) { }

  ngOnInit() {}

  async closeModal() {
    const onRemovePost: string = "RemovePost";
    await this.popoverController.dismiss(onRemovePost);
  }

}
