import { Injectable } from '@angular/core';
import { Job } from '../../models/job.model';
import { Company } from '../../models/company.model';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import {Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface QueryConfig {
  path: string; //  path to collection
  field: string; // field to orderBy
  limit: number; // limit per query
  reverse: boolean; // reverse order?
  prepend: boolean; // prepend to source?
}

@Injectable({
  providedIn: 'root'
})
export class JobService {

  job: any;
  company: any;

  private query: QueryConfig = {
    field: 'job_active_until',
    limit: 50,
    path: 'jobs',
    prepend: true,
    reverse: true
  };

  private jobsCollection: AngularFirestoreCollection<Job>;
  private companyCollection: AngularFirestoreCollection<Company>;

  private jobs: Observable<Job[]>;
  private companys: Observable<Company[]>;

  constructor(
    public db: AngularFirestore
  ) {
    this.jobsCollection = db.collection<Job>('jobs');
    this.companyCollection = db.collection<Company>('companys');

    this.companys = this.companyCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
   }

   getJobs() {
    this.companyCollection = this.db.collection<Company>('companys');

    this.companys = this.companyCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
        });
      })
    );

    const first = this.db.collection(this.query.path, ref => {
      return ref.orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
                .limit(this.query.limit);
      });
      return first.snapshotChanges().pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
    );
  }

  addJob(job: Job) {
   return this.jobsCollection.add(job);
  }
  getCompany(id= '12') {
    return this.companyCollection.doc<Company>(id).valueChanges();
  }
  getJob(id= '12') {
    return this.jobsCollection.doc<Job>(id).valueChanges();
  }
  deleteJob(id= '12') {
    return this.jobsCollection.doc(id).delete();
  }
  updateJob(id, job: Job) {
    return this.jobsCollection.doc(id).update(job);
  }
  addCompany(id, company: Company) {
    return this.companyCollection.doc(id).set(company); // MAGIC LINE
  }
  updateCompany(id, company: Company) {
    return this.companyCollection.doc(id).update(company);
  }
  getCompanies() {
    return this.companys;
  }

  getWithLimit( starting: any)  {
    const first = this.db.collection(this.query.path, ref => {
      return ref
              .orderBy(this.query.field)
              .startAfter(starting)
              .limit(this.query.limit);
    });

    return first.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  getAllJob() {
    return this.jobsCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }
  getActiveJobs(date) {
    const applicant = this.db.collection(this.query.path, ref => {
      return ref.where('start_date', '<=', date) && ref.where('job_active_until_string', '>=', date)
    });
    return applicant.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }
}
