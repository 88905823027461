import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable , BehaviorSubject } from 'rxjs';
import { Pilot } from '../../models/pilot.model';
import { map } from 'rxjs/operators';
import { TypeRating } from '../../models/typerating.model';
import { Ratings } from '../../models/ratings.model';
import { Qualifications } from '../../models/qulifications.model';
import { Certified } from '../../models/certified.model';
import { InstructorRating } from '../../models/instructor-rating.model';
import { RadioLanguage } from '../../models/radio-language.model';
import { Medical } from '../../models/medical.model';
import { PreviousPositions } from '../../models/previous-positions.model';
import { Availability } from '../../models/availability.model';


const API_URL = environment.apiUrl;
const API_KEY = environment.apiKey;

interface QueryConfig {
  path: string; //  path to collection
  field: string; // field to orderBy
  limit: number; // limit per query
  reverse: boolean; // reverse order?
  prepend: boolean; // prepend to source?
}

@Injectable({
  providedIn: 'root'
})
export class ApplicantService {

  private _done = new BehaviorSubject(false);
  private _loading = new BehaviorSubject(false);
  private _data = new BehaviorSubject([]);

  private query: QueryConfig = {
    field: 'name',
    limit: 50,
    path: 'pilots',
    prepend: true,
    reverse: true
  };

  // Observable data
  data: Observable<any>;
  done: Observable<boolean> = this._done.asObservable();
  loading: Observable<boolean> = this._loading.asObservable();
  currentPilot: any;

  private applicantsCollection: AngularFirestoreCollection<Pilot>;

  private typeRatingCollection: AngularFirestoreCollection<TypeRating>;

  private ratingsCollection: AngularFirestoreCollection<Ratings>;

  private certifiedCollection: AngularFirestoreCollection<Certified>;

  private qualificationsCollection: AngularFirestoreCollection<Qualifications>;

  private instructorRatingCollection: AngularFirestoreCollection<InstructorRating>;

  private radioLanguageCollection: AngularFirestoreCollection<RadioLanguage>;

  private medicalCollection: AngularFirestoreCollection<Medical>;

  private previousPositionCollection: AngularFirestoreCollection<PreviousPositions>;

  private availabilityCollection: AngularFirestoreCollection<Availability>;

  private pilots: Observable<Pilot[]>;

  private typeratings: Observable<TypeRating[]>;

  constructor(
    private http: HttpClient,
    public db: AngularFirestore
  ) {
    this.applicantsCollection = db.collection<Pilot>('pilots');
    this.typeRatingCollection = db.collection<TypeRating>('typeratings');
    this.ratingsCollection  = db.collection<Ratings>('ratings');
    this.certifiedCollection = db.collection<Certified>('certifieds');
    this.qualificationsCollection = db.collection<Qualifications>('qualifications');
    this.instructorRatingCollection = db.collection<InstructorRating>('instructor_ratings');
    this.radioLanguageCollection = db.collection<RadioLanguage>('radio_lagnuages');
    this.medicalCollection = db.collection<Medical>('medicals');
    this.previousPositionCollection  = db.collection<PreviousPositions>('previous_positions');
    this.availabilityCollection = db.collection<Availability>('availabilities');

    // const first = this.db.collection(this.query.path, ref => {
    //   return ref
    //           .orderBy(this.query.field)
    //           .limit(this.query.limit)
    // })

    // this.pilots = first.snapshotChanges().pipe(
    //   map(actions=>{
    //     return actions.map(a=>{
    //       const data = a.payload.doc.data();
    //       const id = a.payload.doc.id;
    //       return { id, ...data }
    //     })
    //   })
    // );

    this.typeratings = this.typeRatingCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
   }


  getData(url) {
    return this.http.get(url);
  }

  getApplicant(id) {
    return this.applicantsCollection.doc<Pilot>(id).valueChanges();
  }

  getRatings(id) {
    return this.ratingsCollection.doc<Ratings>(id).valueChanges();
  }

  getCertified(id) {
    return this.certifiedCollection.doc<Certified>(id).valueChanges();
  }

  getQulafication(id) {
    return this.qualificationsCollection.doc<Qualifications>(id).valueChanges();
  }

  getInstructorRating(id) {
    return this.instructorRatingCollection.doc<InstructorRating>(id).valueChanges();
  }

  getRadioLanguage(id) {
    return this.radioLanguageCollection.doc<RadioLanguage>(id).valueChanges();
  }

  getMedical(id) {
    return this.medicalCollection.doc<Medical>(id).valueChanges();
  }

  getPreviousPosition(id) {
    return this.previousPositionCollection.doc<PreviousPositions>(id).valueChanges();
  }

  getAvailability(id) {
    return this.availabilityCollection.doc<Availability>(id).valueChanges();
  }

  addApplicant(applicant: Pilot, id) {
    return this.applicantsCollection.doc(id).set(applicant);
  }

  addRatings(ratings: Ratings, id) {
    return this.ratingsCollection.doc(id).set(ratings);
  }

  addTypeRating(typerating: TypeRating, id) {
    return this.typeRatingCollection.doc(id).set(typerating);
  }

  addCertified(certified: Certified, id) {
    return this.certifiedCollection.doc(id).set(certified);
  }

  addQualification(qualifications: Qualifications, id) {
    return this.qualificationsCollection.doc(id).set(qualifications);
  }

  addInstructorRating(instructorRating: InstructorRating, id) {
    return this.instructorRatingCollection.doc(id).set(instructorRating);
  }

  addRadioLanguage(radioLanguage: RadioLanguage, id) {
    return this.radioLanguageCollection.doc(id).set(radioLanguage);
  }

  addMedical(medical: Medical, id) {
    return this.medicalCollection.doc(id).set(medical);
  }

  addPreviousPosition(previousPositions: PreviousPositions, id) {
    return this.previousPositionCollection.doc(id).set(previousPositions);
  }

  addAvailAbility(availability: Availability, id) {
    return this.availabilityCollection.doc(id).set(availability);
  }

  getTypeRating(id) {
    return this.typeRatingCollection.doc<TypeRating>(id).valueChanges();
  }

  getApplicants() {
    const first = this.db.collection(this.query.path, ref => {
      return ref
              .orderBy(this.query.field)
              .limit(this.query.limit);
    });

    this.typeratings = this.typeRatingCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    return first.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  getWithLimit( starting: any) {
    console.log(starting);
    const first = this.db.collection(this.query.path, ref => {
      return ref
              .orderBy(this.query.field)
              .startAfter(starting)
              .limit(this.query.limit);
    });

    return first.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  updateApplicant(applicant: Pilot, id) {
    return this.applicantsCollection.doc(id).update(applicant);
  }

  updateRatings(ratings: Ratings, id: string) {
    return this.ratingsCollection.doc(id).update(ratings);
  }

  updateCertified(certified: Certified, id: string) {
    return this.certifiedCollection.doc(id).update(certified);
  }

  updateQualifications(qualifications: Qualifications, id: string) {
    return this.qualificationsCollection.doc(id).update(qualifications);
  }

  updateInstructorRating(instructorRating: InstructorRating, id: string) {
    return this.instructorRatingCollection.doc(id).update(instructorRating);
  }

  updateRadioLanguage(radiLanguage: RadioLanguage, id: string) {
    return this.radioLanguageCollection.doc(id).update(radiLanguage);
  }

  updateMedical(medical: Medical, id: string) {
    return this.medicalCollection.doc(id).update(medical);
  }

  updatePreviousPosition(previousPositions: PreviousPositions, id: string) {
    return this.previousPositionCollection.doc(id).update(previousPositions);
  }

  updateAvailability(availability, id: string) {
    return this.availabilityCollection.doc(id).update(availability);
  }

  removeApplicant(id) {
    return this.applicantsCollection.doc(id).delete();
  }

  getAllApplicant() {
    return this.applicantsCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  getApplicantAnonymous() {
    const first = this.db.collection('pilots', ref => {
      return ref.where('anonymous_looking','==',true);
    });
    return first.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }
}
